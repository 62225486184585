// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/entry.client.tsx"
);
import.meta.hot.lastModified = "1715606762757.7747";
}
// REMIX HMR END

import { RemixBrowser } from '@remix-run/react'
import { startTransition, StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'
import i18n from '~/i18n'
import i18next from 'i18next'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { getInitialNamespaces } from 'remix-i18next/client'
import enGB from './locales/en-GB'
import { AnalyticsContext } from './AnalyticsContext'

declare global {
  interface Window {
    plausible?: any
    _iub?: any
    dataLayer?: any[]
  }
}

async function hydrate() {
  await i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      ...i18n,
      ns: getInitialNamespaces(),
      detection: { order: ['htmlTag'], caches: [] },
      resources: { 'en-GB': { translation: enGB } }
    })

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        <StrictMode>
          <AnalyticsContext.Provider
            value={{
              pushGTMVars(vars) {
                window.dataLayer = window.dataLayer || []

                window.dataLayer.push(vars)
              },
              trackEvent:
                // https://plausible.io/docs/custom-event-goals#trigger-custom-events-manually-with-a-javascript-function
                function () {
                  if (window.plausible) {
                    window.plausible(...arguments)
                  } else {
                    window.plausible = window.plausible || {}
                    window.plausible.q = window.plausible.q || []
                    window.plausible.q.push(arguments)
                  }
                }
            }}
          >
            <RemixBrowser />
          </AnalyticsContext.Provider>
        </StrictMode>
      </I18nextProvider>
    )
  })
}

if (window.requestIdleCallback) {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  window.requestIdleCallback(hydrate)
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  window.setTimeout(hydrate, 1)
}
